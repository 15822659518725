.terr{
    position:fixed;
    margin-top: -500px;
}
.nimdaheader{
    position:fixed;
    background-color: rgba(14, 12, 13, 0.843);
    width: 100%;
    height:100px;
    color:white;
}
.user{
    position: inherit;
    margin-left:50px;
    margin-top:20px;
}
.user img{
    width:50px;
    height: 50px;
    border-radius: 10px;
}
.user h1{
    margin-left:50px;
    margin-top:-50px;
    padding:5px;
}

.nimdaheader ul{
    background-color: rgba(14, 12, 13, 0.843);
    list-style: none;
    font-family: Arial, Helvetica, sans-serif;
    margin-left:300px;
}
.nimdaheader ul input{
    padding:10px;
    border-radius: 10px;
    margin-left:20px;
}
.nimdaheader ul button{
    padding:10px;
    border-radius: 10px;
    background-color: #303030;
    color:yellow;
    border: 1px solid yellow;
    margin:5px;
    cursor: pointer;
}
.nimdaheader ul li{
    float: left;
    padding:10px;
    cursor: pointer;
}
.nimdaheader ul li:hover{
    background-color: #303030;
}
.allplayersinf{
    position:fixed;
    overflow: scroll;
    margin-top:150px;
    width:100%;
    height:100%;
}
.allplayersinf h1{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left:500px;

}
.ont{
    background-color: #303030;
    position: absolute;
    margin-left:5px;
    margin-top:50px;
}

.ontk{
    position: absolute;
    margin-left:150px;
    margin-top:50px;
}
.ontkk{
    position: absolute;
    margin-left:250px;
    margin-top:50px;
}
.twt{
    position: absolute;
    margin-left:380px;
    margin-top:50px;
    
}
.tht{
    position: absolute;
    margin-left:500px;
    margin-top:50px;
    width:100px;
}
.fot{
    position: absolute;
    margin-left:650px;
    margin-top:50px;
}
