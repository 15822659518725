.profilehead{
    position: fixed;
    width:100%;
    height:100px;
    background-color: rgba(20, 19, 19, 0.469);
    box-shadow: 10px 10px 30px black;
}

.profilehead img{
    width:50px;
    height:50px;
    border-radius:30px;
    padding:10px;
    margin-left:20px;
    cursor: pointer;
}
.profilehead h3{
    color:white;
    margin-left:90px;
    margin-top:-50px;
    font-family: Arial, Helvetica, sans-serif;
    font-size:15px;
}

.profilehead h4{
    color:white;
    margin-left:90px;
    margin-top:-10px;
    font-size:12px;
}

.profilehead h1{
    position: absolute;
    margin-left:350px;
    margin-top:-20px;
    
    color:white;
    
    font-family: Arial, Helvetica, sans-serif;
}
.profilehead ul{
    position:fixed;
    margin-left:600px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:-50px;
}
.ham{
    position:fixed;
    width:50px;
    height:50px;
    margin-left:700px;
    margin-top:40px;
    display:none;
}
.ham div{
    padding:5px;
    background-color: white;
    border-radius: 10px;
    font-size: 10px;
    width:50px;
    margin:5px;
    cursor:pointer;
}

.showhi{
    position:absolute;
    background-color: rgb(31, 27, 27);
    width:200px;
    color:white;
    margin-left:200px;
    height: 300px;
    display: none;
}
.showhi ul{
    list-style: none;
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.showhi ul li:hover{
    background-color: black;
    padding:5px;
}

@media(max-width:800px){
    .ham{
        display:block;
        margin-left:500px;
    }
}
@media(max-width:500px){
    .ham{
        margin-left:300px;
        margin-top:10px;
    }
    .profilehead h1{
        display:none;
    }
    .prof button{
        position:absolute;
        margin-left:-70px;
        width:50px;
        height:50px;
    }

}
@media(max-width:1200px){
    .profilehead ul{
        position:fixed;
        margin-left:300px;
    }
    .profilehead h1{
        position:fixed;
        margin-left:190px;
        font-size: 25px;
    }
  
}

@media(max-width:900px){
    .profilehead ul{
        display: none;
    }
}
.profilehead ul li{
    float:left;
    padding:10px;
    list-style: none;
}
.profilehead ul li p{
    cursor: pointer;
    background-color: rgb(49, 12, 12); 
    padding:10px;
    border:none;
    border-radius: 5px;
}
.profilehead ul li p:hover{
    background-color: brown;
    transition: 1s;
}
.prof{
    position:fixed;
    margin-top:130px;
    width:600px;
    height:500px;
    border:1px solid rgb(40, 40, 38);
    padding:10px;
    margin-left:300px;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    overflow: scroll;
    
}
@media(max-width:1200px){
    .prof{
        position:fixed;
        margin-left:100px;
    }
}
@media(max-width:500px){
    .prof{
        margin-left:10px;
    }
    .btnr{
        width:100px;
        height:70px;
    }
}
.prof select{
    width:280px;
    padding:10px;
    background-color: rgb(40, 40, 38);
    color:gray;
}
.prof h3{
    background-color: rgb(18, 16, 16);
    margin-left:200px;
    padding:10px;
    padding-left:150px;
}
.prof button{
    background-color: rgb(70, 16, 16);
    width:180px;
    height:180px;
    border:none;
    border-radius: 50px;
    font-size: 100px;
}

.prok{
    margin-left:250px;
    margin-top:10px;
}
.prok button{
    width:270px;
    height: 50px;
    background-color:rgb(0, 85, 255);
    padding:20px;
    margin-top:20px;
    border:none;
    border-radius:10px;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.prok button:hover{
    background-color: rgb(21, 18, 18);
    transition:1s;
    color:white
}
.prok input{
    text-align: center;
    padding:10px;
    border:1px solid yellow;
    border-radius:5px;
}

@media(max-width:500px){
    .prof h3{
        margin-left:5px;
    }
    .prok{
        margin-left:5px;
    }
    .prok button{
        width:600px;
    }
}
.buton--loading::after{
    content: "";
    position: fixed;
    width: 26px;
    height: 26px;
    top:400px;
    left: 250px;
    right:100px;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}


.loading::after{
    content: "";
    position: fixed;
    width: 16px;
    
    height: 16px;
    top:0px;
    left: 500px;
    right:0;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}