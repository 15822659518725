.gentle{
    position: fixed;
    height:100%;
    overflow: scroll;
    margin-top:-100px;
    overflow-y: none;
}
.gentle img{
    width:100%;
    
}
.intro{
    position:fixed;
    width:100%;
    height:100%;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.383);
    margin-top:-110px;
    overflow-y: none;
}
.introOne{
    margin-left:50px;
    margin-top:70px;
}
.introOne h1{
    font-family: Arial, Helvetica, sans-serif;
    color:yellow;
}
.introOne p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.introOne button{
    width:200px;
    background-color: rgb(255, 0, 204);
    color:white;
    border:none;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    padding:20px;
    cursor:pointer;
}
.introOne button:hover{
    background-color:black;
    transition:1s;
}
.playlist{
    position:fixed;
    margin-left:750px;
    background-color:rgba(0, 0, 0, 0.463);
    height:900px;
    overflow:scroll;
    width:600px;
    margin-top:-100px;
    
}
.introThree{
    background-color:rgba(0, 0, 0, 0.567);
    width:400px;
    padding:20px;
    margin:50px;
    border-radius:10px
}

.footer{
    position:fixed;
    margin-top:-250px;
    margin-left:-50px;
    width:100%;
    background-color: black;
    height:200px;
}
.footer button{
    background-color: black;
    margin-left:550px;
    margin-top:-250px;
    padding-top:-50px;
    font-size: 30px;
    width:100px;
    height:30px;
}
.footer img{
    width:20px;
    height:20px;
    border-radius: 40px;
    
}
.footer h3{
    font-family:Arial, Helvetica, sans-serif;
    border-bottom:2px solid rgb(35, 34, 34);
    width:150px;
    font-size: 15px;
   
}
.footer p{
    color:white;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 40px;
    margin-top:-40px;
    font-size: 15px;
    cursor: pointer;
}

.footertwo{
    margin-top:-90px;
    margin-left:250px;
}
.footerthree{
    margin-left:500px;
    margin-top:-90px;
}
.footerfour{
    position:absolute;
    background-color: black;
    margin-left:700px;
    margin-top:-90px;
}
.introThree h1{
    font-family: Arial, Helvetica, sans-serif;
    color:yellow;
}
.introThree p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}
.playlistOne h2{
    font-family: Arial, Helvetica, sans-serif;
    border-bottom:1px solid yellow;
    padding-left:200px;
    color:rgb(18, 207, 207);
}


.round{
    position: fixed;
    height:100%;
    width:100%;
    overflow: scroll;
    overflow-x: hidden;
}

.round button{
    background-color: rgb(255, 255, 6);
    width:50px;
    height:50px;
    border:none;
    border-radius:20px;
    color:green;
}
.round p{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 60px;
    margin-top:-40px;
}
.two{
    background-color: rgba(0, 0, 0, 0.222);
    width:auto;
    height: 50px;
    border-radius: 20px;

}
.tweenty{
    width:50px;
    height:30px;
    background-color: #ff00a623;
    color:white;
    margin-left:160px;
    margin-top:-45px;
    border-radius: 10px;
    color:rgb(13, 149, 120)
}
.round img{
    width:30px;
    height:30px;
    border-radius:30px;
}
.roundone{
    background-color:rgba(34, 33, 33, 0.505);
    margin:10px;
    border-radius:20px;
    width:450px;
}
.roundone p{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 20px;
    font-size: 13px;
}
.roundone button{
    position: absolute;
    margin-left:330px;
    margin-top:-70px;
    padding:10px;
    background-color:yellow;
    color:black; 
    width:100px;
    border:none;
    border-radius: 20px;
    cursor: pointer;
  
}

.round button:hover{
    background-color: black;
    color:yellow;
    transition: 1s;
}
.join{
    top:40px;
    margin-left:20px;
}
.join button{
    width:300px;
    padding:10px;
    height:30px;
    background-color: blue;
    color:white;
    border:none;
    border-radius: 10px;
}





.button--loadingg{
    content: "";
    position: fixed;
    width: 156px;
    height: 156px;
    top:20px;
    margin-left: 90px;
    right:200px;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
   
    border-radius: 50%;
    animation: button-loading-spinner 7s ease infinite;
}

.hpy h1{
    background-color: rgba(0, 0, 0, 0.737);
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
    padding:10px;
    color:gold;
    border-radius: 10px;
    cursor: pointer;
   
}
.hpy img{
    width:80px;
    height:50px;
    padding: 5px;
}
.hpy h1:hover{
    font-size: large;
    transition: 1s;
}

.hpyy h1{
    background-color: rgba(0, 0, 0, 0.737);
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding:10px;
    color:gold;
    border-radius: 10px;
    cursor: pointer;
    rotate: 90deg;
    margin-left: 100px;
    width:120px;
    border-radius: 20px; 
}
.hpyy img{
    width:80px;
    height:50px;
    padding: 5px;
}
.hpyy h1:hover{
    font-size: x-large;
    transition: 1s;
}

.hpyyy h1{

    background-color: rgba(0, 0, 0, 0.737);
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding:10px;
    color:gold;
    border-radius: 10px;
    cursor: pointer;
    rotate: 90deg;
    margin-left: -90px;
    margin-top: -220px;
    width:120px;
    border-radius: 20px;
    rotate: 90deg; 
}
.hpyyy img{
    width:80px;
    height:50px;
    padding: 5px;
}
.hpyyy h1:hover{
    font-size: large;
    transition: 1s;
}
.hp{
    display: none;
   position: fixed;
   background-color: rgba(11, 11, 11, 0.422);
   width:100%;
   height: 100%;
  
}
.hpp{
    background-color: rgba(11, 11, 11, 0.422);
    margin-left:50px;
    margin-top: 50px;
    padding:10px;
    width:300px;
    height: 300px;
    line-height: 20px;
    border-radius: 10px;
}
.hpp h1{
    margin-left: 50px;
    font-family: Arial, Helvetica, sans-serif;
}
.hpp button{
    background-color: black;
    color:red;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    width:30px;
    height:30px;
    margin-left:250px;
    cursor: pointer;
}
.hpp p{
    margin-top: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color:gold;
}

.rule{
    display: none;
   position: fixed;
   background-color: rgba(11, 11, 11, 0.422);
   width:100%;
   height: 100%;
  
}
.ruleone{
    background-color: rgba(11, 11, 11, 0.422);
    margin-left:50px;
    margin-top: 50px;
    padding:10px;
    width:300px;
    height: 300px;
    line-height: 20px;
    border-radius: 10px;
}
.ruleone h1{
    margin-left: 50px;
    font-family: Arial, Helvetica, sans-serif;
}
.ruleone button{
    background-color: black;
    color:red;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    width:30px;
    height:30px;
    margin-left:250px;
    cursor: pointer;
}
.ruleone p{
    margin-top: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color:gold;
}



.winn{
    display: none;
   position: fixed;
   background-color: rgba(11, 11, 11, 0.422);
   width:100%;
   height: 100%;
  
}
.winnone{
    background-color: rgba(11, 11, 11, 0.422);
    margin-left:50px;
    margin-top: 50px;
    padding:10px;
    width:300px;
    height: 300px;
    line-height: 20px;
    border-radius: 10px;
}
.winnone h1{
    margin-left: 50px;
    font-family: Arial, Helvetica, sans-serif;
}
.winnone button{
    background-color: black;
    color:red;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    width:30px;
    height:30px;
    margin-left:250px;
    cursor: pointer;
}
.winnone p{
    margin-top: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color:gold;
}


.tgame{
    display: none;
   position: fixed;
   background-color: rgba(11, 11, 11, 0.422);
   width:100%;
   height: 100%;
  
}
.tgameone{
    background-color: rgba(11, 11, 11, 0.422);
    margin-left:50px;
    margin-top: 50px;
    padding:10px;
    width:300px;
    height: 300px;
    line-height: 20px;
    border-radius: 10px;
}
.tgameone h1{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 50px;
}
.tgameone button{
    background-color: black;
    color:red;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    width:30px;
    height:30px;
    margin-left:250px;
    cursor: pointer;
}
.tgameone p{
    margin-top: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color:gold;
}

.jnt{
   margin-top: 400px;
}
.jnt button{
    width:150px;
    padding:5px;
    background-color: rgba(0, 0, 0, 0.588);
    color:rgb(18, 130, 221);
    cursor: pointer;
}


.jnt{
    margin-top: 400px;
    margin-left:50px;
 }
 .jntt button{
    position:absolute;
     width:150px;
     padding:5px;
     margin-left: 220px;
     margin-top: -50px;
     background-color: rgba(0, 0, 0, 0.588);
     color:rgb(11, 142, 250);
     cursor: pointer;
 }
.mobilenavbar{
    position:fixed;
    background-color: rgba(0, 0, 0, 0.77);
    width:100%;
    height: 100%;
    margin-top:-80px;
    display: none;
}
.mobilenavbarone{
    width:50%;
    height:100%;
    background-color:black;
    color:white;
    margin-left:-250px;
   margin-top:-10px;
}
.mobilenavbarone ul li{
    padding:10px;
    margin:10px;
    list-style: none;
    border-bottom:1px solid yellow;
    cursor: pointer;
}
.mobilenavbarone ul li:hover{
    background-color: rgb(22, 19, 19);
    color:yellow;
}
.mobilenavbarone button{
    background-color: black;
    color:white;
    border: none;
    padding:10px;
    padding-top:-50px;
    width:70px;
    cursor: pointer;
}
.mobilenavbarone img{
    position:fixed;
    width:30px;
    height:30px;
    border-radius: 50px;
    margin-left:-50px;
}
.xbutton{
    width:60px;
    height:10px;
    cursor: pointer;
}
.xbutton div{
    background-color:#ff00a6;
    padding:2px;
    margin:5px;
    height: 10px;
    rotate: -180rad;
}
.x2button{
    width:60px;
    height:10px;
    cursor: pointer;
}
.x2button div{
    background-color:#ff00a6;
    padding:2px;
    margin:5px;
    height: 10px;
    margin-top:-20px;
    rotate: 180rad;
}

.foot{
    margin-top: 50px;
    height:200px;
    color:#303030;
   
}
.foot h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    border-bottom: 1px solid #303030;

}
.foot img{
    width:30px;
    height: 30px;
    border-radius: 20px;
    margin-top:5px;
    margin-left: 5px;
}
.foot p{
    margin-left:40px;
}





@media(min-width:1500px){
    body{
        justify-content: center;
        margin-left:300px;
    }
    .footer{
        position:fixed;
        margin-top:-50px;
        margin-left:-50px;
        width:100%;
        background-color: black;
        height:200px;
    }

}


@media(max-width:1000px){
    .gentle img{
       margin-left:-100px;
    }
    .playlist{
        margin-left: 500px;
      }
    
  
}
@media(max-width:800px){
    .gentle img{
        margin-left:-150px;
    }
    .playlist{
        margin-left: 350px;
    }
    
}

@media(max-width:600px){
    .gentle img{
        margin-left:-300px;
    }
    .playlist{
        margin-left: 150px;
    }
    .introOne{
        display: none;
    }
    .introThree{
        display: none;
    }
}

@media(max-width:500px){
    .button--loadingg{
    
        margin-left: 130px;
        right:120px;
        top:-40px;
        bottom: 0;
        
    }
    .gentle img{
        margin-left:-400px;
        width:50%;
        height:100%;
    }
    .playlist{
        background-color:rgba(0, 0, 0, 0.243);
        margin-left:10px;
        width:100%; 
    }
    .playlistOne{
        background-color: rgba(0, 0, 0, 0.243);
        width:100%; 
    }
 
    .playlistOne h2{
        margin-left:-100px;
    }
    .round{
        width:450px;
        
    }
    .roundone{
        width:350px;
    }
    .roundone button{
        width:50px;
        margin-left:300px;
        
    }
    .footer{
        position:absolute;
        margin-top:-50px;
        margin-left:0px;
        width:100%;
        background-color: black;
        height:300px;
    }

    .hp{
       display: none;
       position: fixed;
       background-color: rgba(11, 11, 11, 0.422);
       width:100%;
       height: 100%;
       margin-top:-200px;
       margin-left: -50px;
    }
    .hpp{
        margin-top:200px;
    }
  

    .rule{
        display: none;
        position: fixed;
        background-color: rgba(11, 11, 11, 0.422);
        width:100%;
        height: 100%;
        margin-top:-200px;
        margin-left: -50px;
     }
     .ruleone{
         margin-top:200px;
     }
    
     .winn{
        display: none;
        position: fixed;
        background-color: rgba(11, 11, 11, 0.422);
        width:100%;
        height: 100%;
        margin-top:-200px;
        margin-left: -50px;
     }
     .winnone{
         margin-top:200px;
     }


     .tgame{
        display: none;
        position: fixed;
        background-color: rgba(11, 11, 11, 0.422);
        width:100%;
        height: 100%;
        margin-top:-200px;
        margin-left: -50px;
     }
     .tgameone{
         margin-top:200px;
     }

     .playlist{
        background-color:rgba(0, 0, 0, 0.243);
        margin-left:10px;
        width: 800px; 
    }
    .playlistOne{
        background-color: rgba(0, 0, 0, 0.243);
        width: 800px;
    }
    .ruleone button{
        background-color: black;
        color:red;
        
    }

     
   

    
}

@media(max-width:400px){
    

    .gentle img{
        margin-left:-400px;
        width:40%;
        height:80%;
    }
    .playlist{
        background-color:rgba(0, 0, 0, 0.243);
        margin-left:10px;
        width: 700px; 
    }
    .playlistOne{
        background-color: rgba(0, 0, 0, 0.243);
        width: 700px;
    }
    .playlistOne h2{
        margin-left:-70px;
    }
    .round{
        width:350px;
        
    }
    .round button{
        background-color: rgba(0, 0, 0, 0.588);
        width:140px;
        height:40px;
        font-size: 10px;
        color:rgb(20, 169, 199);
    }
    .round p{
        font-size: small;
        margin-left: 45px;
    }
  
    .roundone{
        width:300px;
    }
    .roundone button{
        width:30px;
        height: 30px;
        margin-left:250px;
    }   
}
