#mainLogin{
    top:50px;
}
.loginForm{
    position:absolute;
    width:700px;
    margin-left:270px;
    margin-top:-50px;
    height:500px;
    background-color: rgba(0, 0, 0, 0.623);
    
}
.login{
    margin-left: 100px;
    padding-top:100px;
    font-family: Arial, Helvetica, sans-serif;
}
.login h1{
   background-color: rgba(248, 19, 195, 0.617);
   width:200px;
   padding-left:100px; 
   margin-left:50px;
   border:none;
   border-radius: 20px;
}
.login button{
    width:300px;
    background-color: rgba(39, 186, 240, 0.664);
    color:black;
    margin-left:50px;
    padding:20px;
    border:none;
    border-radius: 10px;
    cursor: pointer;
}
.login button:hover{
    background-color: rgba(0, 0, 0, 0.8);
    color:rgb(18, 183, 205);
    transition: 1s;
}
.input-field{
    position:relative;
    width:100px;
    margin-left:80px;

}
label{
    position:absolute;
    top:5px;
    margin-left:80px;
    width:150px;
    color:white;
    transition:0.2s all;
    cursor:text
}
input{
    width:250px;
    border:0;
    outline:0;
    padding:0.5rem 0;
    box-shadow:none;
    background-color:rgba(18, 17, 17, 0.446);
    color:white;
    border:1px solid yellow;
}
input:invalid{
    outline:0;
}
input:focus,
input:valid{
    border-color:white;
}
input:focus~label,
input:valid~label{
    font-size:11px;
    top:-10px;
    width:100px;
    background-color:rgba(23, 21, 21, 0.732);
    color:white;
}
.addimg{
    position:absolute;
    margin-left:300px;
    margin-top:-25px;
}
.addimg img{
    width:20px;
    height:20px;
}

@media(max-width:1000px){
    .loginForm{
        margin-left:200px;
             
    }
}
@media(max-width:900px){
    .loginForm{
        margin-left:100px;
    }
}
@media(max-width:800px){
    .loginForm{
        margin-left: 20px;
        width:550px;
    }
    
}
@media(max-width:700px){
    .loginForm{
        margin-left: -20px;
        width:450px;
    }
   
}
@media(max-width:600px){
    .loginForm{
        margin-left:20px;
        width:450px;
    }
    .login{
        margin-left: -20px;
    }
    .shamberger{
        display: block;
    }
    
}
@media(max-width:500px){
    .loginForm{
        margin-left:-10px;
        width:400px;
    }
    .login{
        margin-left: -10px;
        margin-top:-80px;
        padding-top:100px;
        font-family: Arial, Helvetica, sans-serif;
    }
    
    
}

@media(max-width:300px){
    .loginForm{
        position:fixed;
        margin-left:-40px;
        width:300px;
    }
    .loginForm button{
        width:200px;
        height: 20px;
        margin-left:80px;
    }
    .loginForm img{
        margin-left: -50px;
    }
    .loginForm h1{
        padding-left:100px;
        padding-top:10px;
        padding-bottom: 10px;
        width:150px;
        font-size: medium;
        margin-left:60px;         
    }
    .loginForm input{
          width:200px;        
    }
}