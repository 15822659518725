.allcards{
    position:fixed;
    margin-left:800px;
    margin-top:150px;
    width:380px;
    height: 650px;
   
    border-radius: 5px;
    overflow: auto;
}
.allcards h1{
    position:fixed;
    background-color: rgb(17, 17, 17);
    width:270px;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    margin-left:50px;
    margin-top: -10px;
    border-radius: 5px;
    border-bottom: 1px solid yellow;
    padding-left:150px;
    padding:10px;
}
.cardList{
    padding:20px;
    margin-left:50px;
    width:250px;
    background-color: rgb(11, 9, 9);
    border-radius:20px;
   
}

.cardList button{
    padding:10px;
    margin:10px;
    border:none;
    border-radius:50px;
    text-align: center;
}

.playersArena{
    position:fixed;
    margin-left:450px;
    margin-top:150px;
    width:380px;
    height: 650px;
    border-radius: 5px;
    overflow: auto;
}
.gamesection{
    background-color:#151515;
    padding:10px;
    margin:10px;
    height:200px;
    border-radius:10px;

}
.cardsection{
    position:fixed;
    background-color:#0d0c0c;
    padding:10px;
    margin:10px;
    width:350px;
    height:230px;
    border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
}



.bin{
    position:fixed;
    background-color:rgb(11, 8, 8);
    width:100%;
    
}
.button--loading::after{
    content: "";
    position: fixed;
    width: 26px;
    height: 26px;
    top:350px;
    left: 0;
    right:100px;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}


.loading::after{
    content: "";
    position: fixed;
    width: 16px;
    
    height: 16px;
    top:0px;
    left: 500px;
    right:0;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}


.playersInfo{
    width:250px;
    height:8000px;
    position:fixed;
    border: 1px solid yellow;
    top:145px;
    overflow: auto;
     
}
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(72, 151, 72);
    border-radius: 10px;
}

.detail{
    position:fixed;
    height: 700px;
    width:250px;
    background-color: rgba(11, 9, 9, 0.588);
    margin-top:150px;
    display: none;
    
}
.userdetail{
    width:220px;
    height: 400px;
    margin-top:0px;
    margin-left:10px;
    background-color: #303030;
    border-radius: 10px;
}
.userdetaill{
    width:220px;
    position: fixed;
    margin-left: 2px;
    margin-top: 200px;
    background-color: #303030;
    border-radius: 10px;
    height: 200px;
}
.userdetail button{
    position:absolute;
    
    padding:10px;
    background-color: #0d1dcf;
    border:none;
    margin-left:20px;
    border-radius: 10px;
    color:white;
    margin-top:340px;
    width:150px;
    cursor: pointer;
}
.userdetail button:hover{
    background-color: red;
    color:white;
}

.pb button{
    position: fixed;
    background-color: black;
    color:yellow;
    border:1px solid rgb(255, 0, 183);
    padding:10px;
    width:40px;
    height:40px;
    border-radius: 20px;
   justify-content: center;
}

.pi button{
    position: fixed;
    background-color: black;
    color:yellow;
    border:1px solid rgb(255, 0, 183);
    padding:10px;
    width:40px;
    height:40px;
    margin-left:50px;
    margin-top:0px;
    border-radius: 20px;
   justify-content: center;
}

.pn button{
    position: fixed;
    background-color: black;
    color:yellow;
    border:1px solid rgb(255, 0, 183);
    padding:10px;
    width:40px;
    height:40px;
    margin-left:100px;
    margin-top:0px;
    border-radius: 20px;
    justify-content: center;
}

.pg button{
    position: fixed;
    background-color: black;
    color:yellow;
    border:1px solid rgb(255, 0, 183);
    padding:10px;
    width:40px;
    height:40px;
    margin-left:150px;
    margin-top:0px;
    border-radius: 20px;
   justify-content: center;
}

.po button{
    position: fixed;
    background-color: black;
    color:yellow;
    border:1px solid rgb(255, 0, 183);
    padding:10px;
    width:40px;
    height:40px;
    margin-left:200px;
    margin-top:0px;
    border-radius: 20px;
   justify-content: center;
}


.selcarnum{
    position:fixed;
}
.selcarnum h3{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: -10px;
    font-size: 15px;
    padding-left: 50px;
    width:270px;
    border-bottom: 1px solid yellow;
}

.pllist h3{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:small;
    border-bottom: 1px solid yellow;
}

.gn{
    border:1px solid rgb(255, 0, 183);
    margin-top:-20px;
}
.gn h3{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight:500px;
    margin-left:50px;
}

.directgamenumber{
    position: fixed;
    height: 250px;
    width:330px;
    margin-top:50px;
    overflow: auto;
}
.rdnumber{
    position:fixed;
    margin-left:250px;
    margin-top:30px;
    background-color: rgb(60, 5, 87);
    padding:10px;
    width:30px;
    height:30px;
    border-radius: 50px;
    color:white;
}
.b{
    position:absolute;
    margin-top:-50px;
}
.i{
    position:absolute;
    margin-top:-50px;
}
.n{
    position:absolute;
    margin-top:-50px;
}
.g{
    position:absolute;
    margin-top:-50px;
}
.o{
    position:absolute;
    margin-top:-50px;
}

.notttcalled{
    width:100%;
    height: 100%;
    background-color: #161212b0;
    display: none;
}
.mobilebottomnav{
    display: none;

}

.gamestart{
    font-family: Arial, Helvetica, sans-serif;
    background-color: #0c010a;
    padding:10px;
    color:white;
    border:1px solid yellow;
    width:140px;
    height:30px;
    border-radius: 10px;
    margin-top:120px;
    margin-left:80px;
}
.winnersbet{
    position:fixed;
    margin-left:270px;
    height:600px;
    width:200px;
    margin-top:100px;
    background-color: #120b0bd5;
    box-shadow: 10px 10px 10px black;
    border-radius: 20px;
    border:1px solid rgb(255, 0, 195);
    overflow: scroll;
    display: none;
    
}
.winnersbet h1{
    font-size: 15px;
    font-family:'Courier New', Courier, monospace;
    padding:10px;
    background-color: rgb(18, 102, 176);
    color:white;
}
.wbtn{
  padding:10px;
  margin:10px;
  border-radius: 20px;
  background-color: #303030;
  color:red;
  cursor: pointer;
  font-size: medium;
  border:none;
}
.wbtn:hover{
    background-color: red;
    color:white;
}
.maincho{
    width:100%;
    height:100%;
    background-color:rgba(13, 10, 10, 0.742);
    position:fixed;
    margin-top:-10px;
    display:none;
}
.cho{
    position:fixed;
    width:200px;
    height:200px;
    background-color:#303030;
    box-shadow:10px 10px 30px black;
    border-radius: 10px;
    margin-top:250px;
    margin-left:80px;
    display:none;
}
.cho button{
    padding:10px;
    background-color: rgb(210, 15, 80);
    color:white;
    border:none;
    border-radius:5px;
    margin-top:20px;
    margin-left:20px;
}
.cho img{
    width:130px;
    height:130px;
    border-radius: 10px;
    margin-left:30px;
    margin-top:10px;
}

.roundlevel{
    position:fixed;
    width:100%;
    height:100%;
    background-color: #090808ea;
    margin-top:-10px;
    display: block;
}
.levelmessage{
    position: fixed;
    width:30%;
    height:65%;
    margin-left:370px;
    margin-top:170px;
    background-color: #212121;
    border-radius: 10px;
     box-shadow: 5px 5px 10px black;
}
.roundlevelchannel{
    position:fixed;
    width:100%;
    height:100%;
    background-color: #090808ea;
    margin-top:-10px;
    display: none;
}
.levelmessagechannel{
    position: fixed;
    width:30%;
    height:65%;
    margin-left:370px;
    margin-top:170px;
    background-color: #212121;
    border-radius: 10px;
     box-shadow: 5px 5px 10px black;
}
.levelmessagechannel h1{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 50px;
    color: gold;
}
.levelmessagechannel p{
    font-family: Arial, Helvetica, sans-serif;
    margin-left:50px;
}
.levelmessagechannel input{
    padding:10px;
    margin-left:50px;
    text-align: center;
    color:gold;
}
.btnx{
    padding:10px;
    margin-left:350px;
    background-color:black;
    color:red;
    border: none;
    cursor: pointer;
}
.btnxx{
    width:300px;
    background-color: rgb(9, 9, 9);
    text-align: center;
    border: none;
    padding: 10px;
    color:wheat;
    margin-left: 40px;
    border-radius: 10px;
    cursor: pointer;
}
.btnxx:hover{
    background-color: #ff00b3;
    transition: 2s;
}
.btnx:hover{
    background-color: red;
    color:black;
    transition:2s;
}
.levelmessage h1{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 100px;
    color: gold;
}
.levelmessage img{
    width:300px;
    height:200px;
    border-radius: 20px;
    margin-left: 40px;
}
.levelmessage p{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 40px;
    line-height: 20px;
}

.channelplace{
    position:fixed;
    width:120px;
    margin-left:260px;
    background-color: black;
    height: 500px;
    overflow: scroll;
    border-radius: 10px;
}
.channelplace p{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color:gray;
    border-bottom: 1px solid gray;
}



.footerrr{
    position: fixed;
    width:100%;
    height:200px;
    background-color: black;
    margin-top:610px;
}
.footerrr button{
    margin-left: 600px;
    width:50px;
    height: 30px;
    background-color: #303030;
    color:white;
    font-size:20px;
    border: none;
    cursor: pointer;
    
}
.footerrr button:hover{
    background-color: rgb(30, 26, 26);
    transition: 1s;
}
.footerrr img{
    width:20px;
    height: 20px;
    border-radius:20px;
}
.footerrr h3{
    font-family:Arial, Helvetica, sans-serif;
    border-bottom: 1px solid #303030;
    width:150px
}
.footerrr p{
    margin-left: 50px;
    margin-top:-35px;
    font-family: Arial, Helvetica, sans-serif;
    color:gray;
}
.powerd{
    position:relative;
    margin-top:50px;
    background-color: #131010;
    height:50px;
}
.powerd p{
    color:white;
    padding:15px;
    margin-left:500px;
}


@media(max-width:1000px){
    .allcards{
        margin-left:550px;
       
    }
    .playersArena{
        width:350px;
        margin-left:230px;
    }
    .cardsection{
        width:320px;
    }
    
    
}

@media(min-width:800px){
    .cho{
        position:fixed;
        width:200px;
        height:250px;
        background-color:#303030;
        box-shadow:10px 10px 30px black;
        border-radius: 10px;
        margin-top:250px;
        margin-left:510px;
        display:none;
    
    }
}
@media(max-width:500px){
    
   
    .mobilebottomnav{
        display:block;
        position:fixed;
        margin-top:540px;
        height:30%;
        border-top: 1px solid yellow;
        background-color:black;
        width:100%;
    
    }
    .mobilebottomnav img{
        width:20px;
        height:20px;
        border-radius: 20px;
    }
    .mobilebottomnav button{
        padding:20px;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        margin-left:20px;
        background-color:black;
        border-radius: 20px;
        color:white;
    }
    .playersInfo{
        width:390px;
        position: fixed;
        background-color: rgb(10, 9, 9);
        margin-left:20px;
       height: 500px;
       font-family: Arial, Helvetica, sans-serif;
    }
    .playersArena{
        position:fixed;
        height: 300px;
        margin-left:50px;
       
    }
    .gn{
        background-color: black;
    }
   
    .cardsection{
        margin-top:-35px;
    }
    .allcards{
        position:absolute;
        height: 450px;
        display:none;
    }
    .detail{
        margin-left:80px;
    }
    .winnersbet{
        margin-left:220px;
        height:600px;
        width:150px;
        margin-top:100px;
    }

    .button--loading::after{
        content: "";
        position: fixed;
        width: 26px;
        height: 26px;
        top:-3px;
        left: 0;
        right:100px;
        bottom: 0;
        margin: auto;
        border:4px solid transparent;
        border-top-color: white;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }
    
    
    .loading::after{
        content: "";
        position: fixed;
        width: 16px;
        
        height: 16px;
        top:0px;
        left: 500px;
        right:0;
        bottom: 0;
        margin: auto;
        border:4px solid transparent;
        border-top-color: white;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }
  
    .levelmessage{
        position: fixed;
        width:90%;
        height:65%;
        margin-left:10px;
        margin-top:10px;
        background-color: #212121;
        border-radius: 10px;
        box-shadow: 5px 5px 10px black;
    }
    .roundlevelchannel{
        position:fixed;
        width:100%;
        height:100%;
        background-color: #090808ea;
        margin-top:-20px;
        display: none;
    }
    .levelmessagechannel{
        position: fixed;
        width:90%;
        height:60%;
        margin-left:10px;
        margin-top:150px;
        background-color: #212121;
        border-radius: 10px;
        box-shadow: 5px 5px 10px black;
    }
 
    .channelplace{
        position:fixed;
        width:120px;
        margin-left:10px;
        background-color: black;
        height: 500px;
        overflow: scroll;
        border-radius: 10px;
    }
   
    
}

@media(min-width:1000px){
    .playersInfo{
        width:390px;
        position: fixed;
        background-color: rgb(10, 9, 9);
        margin-left:50px;
       height: 550px;
    }
    .playersArena{
        position:fixed;
        height: 250px;
        margin-left:450px;
        width:400px;
    }


}

@media(max-width:400px){
    .levelmessage{
        position: fixed;
        width:90%;
        height:65%;
        margin-left:10px;
        margin-top:30px;
        background-color: #212121;
        border-radius: 10px;
        box-shadow: 5px 5px 10px black;
    }
    .levelmessage h1{
        margin-left:50px;
    }
    .levelmessage img{
        margin-left:10px;
    }
    .levelmessage p{
        font-size: 10px;
    }
}
@media(max-width:300px){
    
    .mobilebottomnav{
        position:fixed;
        margin-top:400px;
        height:20%;
        border-top: 1px solid yellow;
        background-color:black;
        width:100%;
    
    }
    .mobilebottomnav img{
        width:10px;
        height:10px;
        border-radius: 20px;
    }
    .mobilebottomnav button{
        padding:10px;
        font-family: Arial, Helvetica, sans-serif;
        border: none;
        margin-left:10px;
        background-color:black;
        border-radius: 20px;
        color:white;
    }
    .allcards{
        margin-left: -25px;
    }
    .cardsection{
        margin-top:-35px;
        margin-left: 5px;
    }

    .playersArena{
        position:fixed;
        height: 250px;
        margin-left:-10px;
        width:300px;
    }
   
  
}