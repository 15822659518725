#mainsingin{
    top:50px;
}

.singinForm{
    position:absolute;
    width:700px;
    margin-left:270px;
    margin-top:-60px;
    height:550px;
    background-color: rgba(0, 0, 0, 0.623);
    
}
.singin{
    margin-left: 100px;
    margin-top:-80px;
    padding-top:100px;
    font-family: Arial, Helvetica, sans-serif;
}
.singin h1{
   background-color: rgba(248, 19, 195, 0.617);
   width:270px;
   font-size:25px;
   padding:10px; 
   margin-left:50px;
   border:none;
   border-radius: 20px;
}
.singin button{
    width:300px;
    background-color: rgba(39, 186, 240, 0.664);
    color:black;
    margin-left:50px;
    padding:20px;
    border:none;
    border-radius: 10px;
    cursor: pointer;
}
.singin button:hover{
    background-color: rgba(0, 0, 0, 0.8);
    color:rgb(18, 183, 205);
    transition: 1s;
}

.shamberger{
    position:fixed;
    margin-left:10px;
    margin-top:-170px;
    height: 0.2px;
   display: none;
}
.shamberger div{
    background-color: rgb(255, 0, 157);
    width:30px;
    padding:3px;
    margin:4px;
   border-radius: 10px;
   cursor: pointer;
    
}

.smobilenavbar{
    position:fixed;
    background-color: rgba(0, 0, 0, 0.77);
    width:100%;
    height: 100%;
    margin-top:-110px;
    display: none;
}
.smobilenavbarone{
    width:50%;
    height:100%;
    background-color:black;
    color:white;
    margin-left:-250px;
}
.smobilenavbarone ul li{
    padding:10px;
    margin:10px;
    list-style: none;
    border-bottom:2px solid yellow;
    cursor: pointer;
}
.smobilenavbarone ul li:hover{
    background-color: rgb(22, 19, 19);
    color:yellow;
}
.smobilenavbarone button{
    background-color: black;
    color:white;
    border: none;
    padding:10px;
    padding-top:-50px;
    width:70px;
    cursor: pointer;
}
.smobilenavbarone img{
    position:fixed;
    width:30px;
    height:30px;
    border-radius: 50px;
    margin-left:-50px;
}

.button{
    position:relative;
    padding:4px;
    background: #009579;
    border: none;
    outline: none;
    border-radius: 2px;
}
.button:active{
    background-color: #007a63;
}
.button__text{
    font:bold 20px 'Quicksand',san-serif;
    color:white;
    transition: all 0.2s;
}
.button--loading::after{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    margin: auto;
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}
.singtxt{
    font-family: Arial, Helvetica, sans-serif;
    height: 5px;
}

.footerr{
    position: fixed;
    width:100%;
    height:500px;
    background-color: black;
    margin-top:370px;
}
.footerr button{
    margin-left: 550px;
    width:50px;
    height: 30px;
    background-color: #303030;
    color:white;
    font-size:20px;
    border: none;
    cursor: pointer;
    
}
.footerr button:hover{
    background-color: rgb(30, 26, 26);
    transition: 1s;
}
.footerr img{
    width:20px;
    height: 20px;
    border-radius:20px;
}
.footerr h3{
    font-family:Arial, Helvetica, sans-serif;
    border-bottom: 1px solid #303030;
    width:200px;

}
.footerr p{
    margin-left:50px;
    margin-top:-35px;
    font-family: Arial, Helvetica, sans-serif;
    color:gray;
}
@keyframes button-loading-spinner{
    from{
        transform: rotate(0turn);
    }
    to{
        transform: rotate(1turn);
    }
}

@media(max-width:1000px){
    .singinForm{
        margin-left:200px;
             
    }
}
@media(max-width:900px){
    .singinForm{
        margin-left:100px;
    }
}
@media(max-width:800px){
    .singinForm{
        margin-left: 20px;
        width:550px;
    }
    
}
@media(max-width:700px){
    .singinForm{
        margin-left: -20px;
        width:450px;
    }
   
}
@media(max-width:600px){
    .singinForm{
        margin-left:20px;
        width:450px;
    }
    .singin{
        margin-left: -20px;
    }
    .shamberger{
        display: block;
    }
    
}
@media(max-width:500px){
    .singinForm{
        margin-left:-10px;
        width:400px;
    }
    .singin{
        margin-left: -10px;
        margin-top:-80px;
        padding-top:100px;
        font-family: Arial, Helvetica, sans-serif;
    }
 
    .footerr{
        position: fixed;
        width:100%;
        height:150px;
        background-color: black;
        margin-top:450px;
        overflow: scroll;
    }
    .footerr button{
        margin-left: 150px;
        width:50px;
        height: 30px;
        background-color: #303030;
        color:white;
        font-size:20px;
        border: none;
        cursor: pointer;
        
    }
    .footerr button:hover{
        background-color: rgb(30, 26, 26);
        transition: 1s;
    }
    .footerr img{
        width:20px;
        height: 20px;
        border-radius:20px;
        margin-left:140px;
    }
    .footerr h3{
        font-family:Arial, Helvetica, sans-serif;
        border-bottom: 1px solid #303030;
        width:100px;
        margin-left:150px;
    }
    .footerr p{
        margin-left:180px;
        margin-top:-25px;
        font-family: Arial, Helvetica, sans-serif;
        color:gray;
    }
    
}

@media(max-width:300px){
    .singinForm{
        position:fixed;
        margin-left:-20px;
        width:300px;
    }
    .singinForm h1{
        font-size: smaller;
        width:200px;
    }
    .singinForm input{
        margin-left:-20px;
        width:230px;
    }
    .singinForm img{
        margin-left: -60px;
    }
    .singinForm label{
        margin-left: -20px;
    }
    .singinForm button{
        width:200px;
        font-size: medium;
    }
    .singin{
        font-size: medium;
        font-family: Arial, Helvetica, sans-serif;
    }   
}