.userListHeader{
    position:fixed;
  background-color:rgba(19, 17, 17, 0.66);
  width:100%;
  height: 100px;
}
.userslist img{
    width:40px;
    height:40px;
    border-radius:50px;
}
.userslist button{
    background-color: yellow;
    color: black;
    width:100px;
    height:40px;
    border:none;
    border-radius:5px;
    cursor: pointer;
    margin-left:-30px;
}
.userslist ul li{
    float: left;
    list-style: none;
    padding:10px;
}
.userslist ul li p{
    font-family: Arial, Helvetica, sans-serif;
    color:#fff;
    font-size: 15px;
    margin-left:80px;
    margin-top:-45px;
}
.userslist ul li img{
    position: absolute;
    margin-left: 3px;
    margin-top: -38px;
}
.userslist ul li button div{
    position:relative;
    width:20px;
    padding: 5px;
    margin: 5px;
    background-color: black;
    border-radius: 10px;
}
.diposite{
    margin-left:500px;
    cursor: pointer;
}
.diposite button:hover{
    background-color: black;
    color:yellow;
    transition: 1s;
    cursor: pointer;
}

.amounts{
    background-color: yellow;
    color:black;
    position:fixed;
    width:100%;
    height: 60px;
    overflow: scroll;
    overflow-x:hidden;
    margin-top:30px;
}
.amounts ul li{
    float: left;
    list-style: none;
    padding:20px;
    margin-top: -15px;
    
}
.amounts ul li.active{
    background-color: black;
    color:white;
}
.amounts ul li:hover{
    background-color: #ff00b3;
    transition: 2s;
    height: 100%;
    color:white;
}

.playsection{
    position:fixed;
    width:57%;
    height: 700px;
    overflow: scroll;
    margin-left:250px;
    margin-top:100px;
    border:1px solid white;
}

.playerattempttoplayy{
    position:fixed;
    width:300px;
    top:50px;
    height:600px;
    margin-left:1000px;
    margin-top:80px;
    border: 1px solid yellow;
    border-radius: 10px;
    overflow: auto;

}

.mobilebottomnavv{
  position:fixed;
  background-color: black;
  margin-top: 500px;
  width:400px;
  height: 100px;
  padding: 10px;
  border:1px solid yellow;
  display: none;
}
.mobilebottomnavv button{
    margin-left:50px;
    background-color: #080808;
    color:white;
    border:none;
}
.mobilebottomnavv img{
    width:30px;
    height: 30px;
    border-radius: 50px;
}
@media(max-width:1000px){
    .playerattempttoplayy{
        margin-left: 720px;
        width:250px;
     }
     .playsection{
        width:45%;
     }
}
.playerattempttoplayy h2{
    position:fixed;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    background-color:#1b1a1b;
    margin:10px;
    width:200px;
    border: 1px solid yellow;
    padding-left: 50px;
    padding-top:20px;

}


.playerattempttoplay p{
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
    margin-left:20px;
    padding: 10px;
    justify-content: center;
    
    
}

.allwinners{
    position:fixed;
    background-color: #0f0f0f;
    width:250px;
    height: 700px;
    margin-top: 85px;
    overflow: auto;
}
.allwinners h2{
    background-color: #0f0f0f;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border:1px solid yellow;
    font-size:15px;
    position:fixed;
    width:200px;
    margin-top:-5px;
    padding-left:50px;
    padding-top:10px;
}
.allwinnerss{
    background-color: black;
    margin: 10px;
    
}
@media(max-width:500px){
    .mobilebottomnavv{
        display: block;
    }
   .allwinners{
      width:350px;
      margin-left:30px;
   }
   .playsection{
    margin-left:-100px;
    display: none;
   }

   .playsection h2{
     margin-left:-180px;
   }
   .playerattempttoplayy{
    margin-left:50px;
    width:500px;
    display: none;
   }
   #demo{
    display: none;
   }
}
.sectionone ul{
    font-family: Arial, Helvetica, sans-serif;
   
}
.sectionone ul li{
    float:left;
    padding:20px;
    list-style: none;
}
.sectionone ul li h3{
    font-size: 20px;
    font-weight: 100;
    margin-top:-20px;
    background-color: rgb(32, 29, 29);
    color:white;
    padding:15px;
}
.sectionone ul li p{
    font-family: Arial, Helvetica, sans-serif;
    justify-content: center;
    color:yellow;
}
.sectionone ul li button{
    background-color: #ff00b3;
    color:white;
    border:none;
    padding: 10px;
    width:100px;
    cursor: pointer;
}
table{
   padding:5px;
}
table tr td h3{
    background-color: rgb(32, 29, 29);
    padding:10px;
    font-family: Arial, Helvetica, sans-serif;
    width:170px;
    font-size: medium;
    font-weight: 100;
}
table tr td button{
    background-color: #ff00b3;
    color:white;
    padding:10px;
    border:none;
    width:100px;
    border-radius: 5px;
    cursor: pointer;
}
table tr td p{
    color:yellow;
    margin-left:10px;
    font-family: Arial, Helvetica, sans-serif;
}
table tr td button:hover{
    background-color: rgb(0, 229, 255);
    color:black;
    transition: 1s;
}



.buttton{
    position:relative;
    padding:8px;
    background: #ff00b3;
    border: none;
    outline: none;
    border-radius: 2px;
}
.buttton:active{
    background-color: #007a63;
}
.buttton__text{
    font:bold 20px 'Quicksand',san-serif;
    color:white;
    transition: all 0.2s;
}
.buttton--loading::after{
    content: "";
    position: fixed;
    width: 16px;
    height: 16px;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    margin: auto;
    padding:20px;
    background-color: rgba(20, 20, 20, 0.316);
    border:4px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}
.singtxt{
    font-family: Arial, Helvetica, sans-serif;
    height: 5px;
    font-size: 15px;
    padding:5px;
    color:white;
}


.ssmobilenavbar{
    position:fixed;
    background-color: rgba(0, 0, 0, 0.561);
    width:100%;
    height: 100%;
    margin-top:-35px;
    display: none;
}
.ssmobilenavbarone{
    width:20%;
    height:100%;
    background-color:rgb(44, 40, 40);
    color:white;
    margin-left:-250px;
    overflow: scroll;
}
.ssmobilenavbarone button{
    background-color: black;
    color:white;
    border: 1px solid yellow;
    width:250px;
    padding: 10px;
}
.roundlevel{
    position:fixed;
    width:100%;
    height:100%;
    background-color: #090808ea;
    display: none;
}
.levelmessage{
    position: fixed;
    width:30%;
    height:65%;
    margin-left:370px;
    margin-top:170px;
    background-color: #212121;
    border-radius: 10px;
}
.btnx{
    padding:10px;
    margin-left:350px;
    background-color:black;
    color:red;
    border: none;
    cursor: pointer;
}
.btnxx{
    width:300px;
    background-color: rgb(9, 9, 9);
    text-align: center;
    border: none;
    padding: 10px;
    color:wheat;
    margin-left: 40px;
    border-radius: 10px;
    cursor: pointer;
}
.btnxx:hover{
    background-color: #ff00b3;
    transition: 2s;
}
.btnx:hover{
    background-color: red;
    color:black;
    transition:2s;
}
.levelmessage h1{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 100px;
    color: gold;
}
.levelmessage img{
    width:300px;
    height:200px;
    border-radius: 20px;
    margin-left: 40px;
}
.levelmessage p{
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 40px;
    line-height: 20px;
}

@media(max-width:500px){
    .userslist ul li p{
        position: absolute;
        margin-left:150px;
       
    }
    .ssmobilenavbarone button{
        width:210px;
    
    }
    .levelmessage{
        width:350px;
        margin-left: 20px;
    
    }
}
.ssmobilenavbarone ul li{
    padding:15px;
    margin-left:-20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    list-style: none;
    border-bottom:1px solid yellow;
    cursor: pointer;
}
.ssmobilenavbarone ul li:hover{
    background-color: rgb(22, 19, 19);
    color:yellow;
}
.cancle{
    border-bottom: 1px solid yellow;
}
.cancle p{
    padding:10px;
    margin:5px;
    color:red;
    width:30px;
    height:20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: xx-large;
    cursor: pointer;
}
.profileOne{
    background-color: #110f0f;
    margin-top: -30px;
}
.profileOne img{
    width:80px;
    height:80px;
    border-radius:60px;
    margin-left:50px;
}

.profileInfo{
    margin-left:5px;
   
}
.profileInfo p{
    color:yellow;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 15px;
}
.profileInfo p img{
    width:20px;
    height: 20px;
    border-radius:20px;
    margin-left:-10px;
    border: none;
}
.medeb{
    position:absolute;
    height: 20px;
    padding:10px;
    width:100%;
    background-color: #363035;
}
.medeb h2{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-top:-5px;
}

@keyframes button-loading-spinner{
    from{
        transform: rotate(0turn);
    }
    to{
        transform: rotate(1turn);
    }
}


@media(max-width:1000px){
   
    .diposite{
        margin-left:300px;
    }
}

@media(max-width:750px){
    .diposite{
        margin-left:150px;
    }

}
@media(max-width:600px){
    .diposite{
        margin-left:50px;
    }
}

@media(max-width:500px){
    .diposite{
    margin-left:12px;
    font-size: 10px;
    width:50px;
    height:30px;
   }

   .userslist ul li p{
    font-family: Arial, Helvetica, sans-serif;
    color:gold;
    font-size: 15px;
    margin-left:75px;
}
#demo{
    background-color: red;
    width:100px;
    height:20px;
    margin-left:60px;
}

.userslist button{
    margin-left: -30px;

}
.playsection{
    position:fixed;
    width:100%;
    height: 700px;
    overflow: scroll;
    margin-left:20px;
    margin-top:100px;
    border:1px solid white;
}

table tr td h3{
    background-color: rgb(32, 29, 29);
    padding:10px;
    font-family: Arial, Helvetica, sans-serif;
    width:80px;
    font-size: small;
    font-weight:x-small;
}
table tr td button{
    background-color: #ff00b3;
    color:white;
    padding:10px;
    border:none;
    width:50px;
    border-radius: 5px;
    cursor: pointer;
}
table tr td p{
    color:yellow;
    margin-left:10px;
    font-family: Arial, Helvetica, sans-serif;
}

.singtxt{
    font-family: Arial, Helvetica, sans-serif;
    height: 3px;
    font-size: 10px;
    padding:5px;
    color:white;
}
.ssmobilenavbarone{
    width:60%;
}
}

@media(max-width:300px){
    .userslist ul li p{
        position: fixed;
        margin-left:250px;
       
    }
    .mobilebottomnavv{
      margin-left: -10px;
      margin-top:400px;   
    }
   .mobilebottomnavv button{
    width:30px;
    height:30px;
    justify-content: center;
   }
   .mobilebottomnavv img{
    width:20px;
    height: 20px;
   }
  

.playsection{
    position:fixed;
    width:95%;
    height: 700px;
    overflow: scroll;
    margin-left:5px;
    margin-top:100px;
    border:1px solid white;
}

table tr td h3{
    background-color: rgb(32, 29, 29);
    padding:5px;
    font-family: Arial, Helvetica, sans-serif;
    width:60px;
    font-size:x-small;
    font-weight:x-small;
}
table tr td button{
    background-color: #ff00b3;
    color:white;
    padding:5px;
    border:none;
    width:30px;
    border-radius: 5px;
    cursor: pointer;
}
table tr td p{
    color:yellow;
    margin-left:5px;
    font-family: Arial, Helvetica, sans-serif;
}

.singtxt{
    font-family: Arial, Helvetica, sans-serif;
    height: 3px;
    font-size:x-small;
    padding:5px;
    color:white;
}

}

@media(max-width:200px){
    .userslist ul li p{
        position:fixed;
        margin-left:250px;
       
    }
    .unm{
        position: fixed;
        margin-left:100px;
    }
    .mobilebottomnavv{
        margin-left: -5px;
        margin-top:350px;  
      }
     .mobilebottomnavv button{
      width:20px;
      height:20px;
      justify-content: center;
     }
     .mobilebottomnavv img{
      width:15px;
      height: 15px;
     }
    
  
  .playsection{
      position:fixed;
      width:85%;
      height: 600px;
      overflow: scroll;
      margin-left:2px;
      margin-top:100px;
      border:1px solid white;
  }
  
  table tr td h3{
      background-color: rgb(32, 29, 29);
      padding:3px;
      font-family: Arial, Helvetica, sans-serif;
      width:50px;
      font-size:xx-small;
      font-weight:x-small;
  }
  table tr td button{
      background-color: #ff00b3;
      color:white;
      padding:3px;
      border:none;
      width:20px;
      border-radius: 5px;
      cursor: pointer;
  }
  table tr td p{
      color:yellow;
      margin-left:3px;
      font-family: Arial, Helvetica, sans-serif;
  }
  
  .singtxt{
      font-family: Arial, Helvetica, sans-serif;
      height: 3px;
      font-size:x-small;
      padding:5px;
      color:white;
  }
}