.mainHeader{
    position:fixed;
    margin-top:-50px;
    background-color: rgba(0, 0, 0, 0.539);
    border:2px solid rgba(47, 45, 45, 0.806);
    width:100%;
}
.logo{
    background-color: black;
    padding:20px;
}
.logo img{
    width:50px;
    height: 40px;
    border-radius: 10px;
    margin-top:30px;
}
.logo h1{
    margin-left: 50px;
    margin-top:-45px;
    padding:10px;
    font-size: 20px;
    color:rgb(245, 210, 12);
}
.headone{
    margin-top:-100px;
    margin-left:500px;
    text-decoration: none;
    list-style: none;
    
}
.headone ul li{
    float:left;
    list-style: none;
    margin-top:25px;
    border:none;
    border-radius:20px;
    font-family: Arial, Helvetica, sans-serif;
    cursor:pointer;
}
.headone ul li button{
    padding:10px;
    margin:3px;
    width:100px;
    border:none;
    background-color: rgb(64, 64, 88);
    color:white;
    border-radius:10px;
    cursor: pointer;
}
.headone ul li:hover{
    border:1px solid yellow;
    transition: 5s;
}
.headone ul li button:hover{
    background-color:black;
    transition:1s;
}
.logo{
    margin-top:-95px;
    background-color:black;
    position:fixed;
    margin-left:180px;
    font-family: Arial, Helvetica, sans-serif;
    padding:3px; 
 
}

.hamberger{
    position:fixed;
    margin-left:10px;
    margin-top:-110px;
    height: 0.2px;
    display: none;
}
.hamberger div{
    background-color: rgb(255, 0, 157);
    width:30px;
    padding:3px;
    margin:4px;
   border-radius: 10px;
   cursor: pointer;
    
}
@media(min-width:850px){
    .headone{
        margin-left:800px;
    }
}

@media(max-width:1000px){
    .headone{
       margin-left: 300px;       
    }
}
@media(max-width:800px){
    .hamberger{
        display: block;
    }
   .headone{
    margin-left:150px;
   }
    .logo{
        background-color: black;
        margin-left:80px;
        color:rgb(255, 0, 157);
    }
    .headone{
        display: none;
    }
    .logo{
        background-color: black;
        margin-left:80px;
        color:rgb(255, 0, 157);
    }
}
@media(max-width:600px){
    .headone{
        display: none;
    }
    .logo{
        background-color: black;
        margin-left:80px;
        color:rgb(255, 0, 157);
    }
    
}
@media(max-width:500px){
    .headone{
        display: none;
    }
    .logo{
        background-color: black;
        margin-left:80px;
        color:rgb(255, 0, 157);
    }
   
}
@media(max-width:300px){
    .logo img{
        width:40px;
        height: 30px;
        border-radius: 10px;
        margin-top:30px;
    }
    .logo h1{
        margin-left: 35px;
        margin-top:-35px;
        padding:10px;
        font-size: 15px;
        color:rgb(245, 210, 12);
    }

}