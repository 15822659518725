.message{
    position:fixed;
    overflow: scroll;
    background-color: #100f0f9b;
    width:500px;
    margin-left:350px;
    height:500px;
    border:1px solid yellow;
    border-radius: 20px;
    overflow-x: hidden;
}
.mainmessage{
    position: fixed;
    width:250px;
    margin-left:240px;
    height:430px;
    overflow: scroll;
    overflow-x: hidden;
}
.sendingarea{
    position:fixed;
    margin-left:100px;
    margin-top:450px;
}
.sendingarea input{
    padding:10px;
    background-color: black;
    color:yellow;
    border:1px solid blue;
    border-radius: 5px;
}
.sendingarea button{
    width:70px;
    height:40px;
    font-size:10px;
    background-color: rgb(255, 0, 179);
    color:white;
    border-radius:8px;
}
.activeperson{
    width:350px;
    position: fixed;
    height:450px;
    overflow: scroll;
    overflow-x: hidden;
}
.activeperson h2{
    border-bottom: 1px solid rgb(255, 0, 179);
    padding:5px;
    position:fixed;
}
.online{
    margin-left:900px;
}
.online h2{
    border-bottom: 1px solid rgb(255, 0, 179);
    padding:5px;
    position:fixed;
    background-color: #303030;
    margin-top:-10px;
}