.prof{
    width:1150px;
    margin-left:20px;
}
.nic{
    position:fixed;
    width:auto;
    height:70px;
    margin-top:3px;
    background-color: #1a1919ec;
}
.nic ul{
    list-style: none;
    margin-top:-30px;
    font-size: 10px;
}

.nic ul li{
    float: left;
    padding:20px;
}

.dat{
    width:40px;
    position:fixed;
    margin-left:50px;
}
.ont{
    position: absolute;
    margin-left:50px;
    margin-top:50px;
}
.twt{
    position: absolute;
    margin-left:280px;
    margin-top:50px;
    
}
.tht{
    position: absolute;
    margin-left:400px;
    margin-top:50px;
    width:100px;
}
.fot{
    position: absolute;
    margin-left:600px;
    margin-top:50px;
}
.fott{
    position: absolute;
    margin-left:850px;
    margin-top:50px;
}

.fottt{
    position: absolute;
    margin-left:950px;
    margin-top:50px;
}
@media(max-width:500px){
    .nic ul{
        margin-left:-40px;
        float:left;
    }
    .nic ul li h1{
    font-size:15px;
  } 
  
  .ont{
    margin-left:10px;
  }
  .twt{
    margin-left:150px;
  }
  .tht{
    margin-left:220px;
  }
  .fot{
    margin-left:270px;
  }
}