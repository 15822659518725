.profilehead h3{
  color:gold;
}
.profilehead h4{
  color:gold;
}
.nic{
    position:fixed;
    width:auto;
    height:70px;
    margin-top:3px;
    background-color: #1a1919ec;
}
.nic ul{
    list-style: none;
    margin-top:-30px;
    font-size: 15px;
}

.nic ul li{
    float: left;
    padding:20px;
}

.dat{
    width:40px;
    position:fixed;
    margin-left:50px;
}
.ont{
    position: absolute;
    margin-left:50px;
    margin-top:50px;
}
.twt{
    position: absolute;
    margin-left:180px;
    margin-top:50px;
    
}
.tht{
    position: absolute;
    margin-left:300px;
    margin-top:50px;
    width:100px;
}
.fot{
    position: absolute;
    margin-left:450px;
    margin-top:50px;
}

.nvb{
  margin-top: 50px;
}

.nvb div{
  width:30px;
  height: 0.5px;
  background-color: gold;
  padding:3px;
  margin: 3px;
  
}
.profilehead img{
  margin-left:35px;
  margin-top: -50px;
 
  
}
.playhis{
  position:fixed;
  width:800px;
  margin-top: 50px;
  overflow: scroll;
 }
.picc{
  margin-left:400px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  
}
.picc p{
  margin-left: 50px;
  padding: auto;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
@media(max-width:500px){
  .picc{
    display: none;
  }
   .playhis{
    position:fixed;
    overflow: scroll;
   }
  
    .profilehead img{
      width:40px;
      height:40px;
    }
    .nic ul{
      width:600px;
    }  
    .nic ul{
        margin-left:-20px;
        
    }
    .nic ul li h1{
    font-size:15px;
  }   
  .ont{
    margin-left:10px;
  }
  .twt{
    margin-left:110px;
  }
  .tht{
    margin-left:170px;
  }
  .fot{
    margin-left:250px;
  }
}